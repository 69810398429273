import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "typeface-dosis"
import MainHeader from "../components/mainHeader"
import Footer from "./footer"
import "./layout.css"

const Layout = ({
  children,
  portadaDesk,
  portadaMob,
  isIndex,
  title,
  isContacto,
}) => {
  return (
    <>
      <MainHeader
        portadaDesk={portadaDesk}
        portadaMob={portadaMob}
        title={title}
        isIndex={isIndex}
      />
      <main>{children}</main>
      {/* si es que no es la pagina de contacto, display Footer */}
      {!isContacto && <Footer />}
    </>
  )
}

Layout.defaultProps = {
  isContacto: false,
  isIndex: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
