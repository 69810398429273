import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../components/contacto.module.css"

export default ({ data }) => {
  const portada = data.portada
  const portadaM = data.portadaM

  return (
    <Layout
      portadaDesk={portada}
      portadaMob={portadaM}
      title="Contacto"
      isContacto={true}
    >
      <SEO title="Contacto" />
      <main>
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <h2>Dirección:</h2>
            <p>Arquitecto Miguel Angel Alfaro 127 casi Santísima Trinidad</p>
            <a
              href="https://www.google.com/maps/place/Allinea+Pilates/@-25.2674671,-57.5676543,17z/data=!3m1!4b1!4m5!3m4!1s0x945da611e8d74f95:0xfc64967c01dab8e2!8m2!3d-25.2674671!4d-57.5654656"
              target="_blank"
              rel="noopener noreferrer"
            >
              COMO LLEGAR - GOOGLE MAPS
            </a>
          </div>
          <div className={styles.container}>
            <h2>Teléfono:</h2>
            <p className={styles.openSans}>+595 981 597069</p>
          </div>
          <div className={styles.container}>
            <h2>Mail:</h2>
            <p>allineapilatespy@outlook.com</p>
          </div>
          <div className={styles.container}>
            <h2>Horarios:</h2>
            <p>
              Lunes a Viernes:{" "}
              <span className={[styles.openSans, styles.span].join("")}>
                07:00 - 20:00
              </span>
            </p>
            <p>
              Sábados:{" "}
              <span className={[styles.openSans, styles.span].join("")}>
                10:00 - 18:00
              </span>
            </p>
            <p>Domingos: cerrado</p>
          </div>
          <div className={styles.svgContainer}>
            <a
              href="https://wa.me/595981597069?text=Hola%20Allinea!%20Estoy%20interesado%20en%20sus%20clases"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="61"
                height="61"
                viewBox="0 0 61 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.144531 61L4.43232 45.3357C1.78645 40.7505 0.396156 35.5528 0.398698 30.223C0.406323 13.5598 13.9661 0 30.6267 0C38.7118 0.00254167 46.3012 3.15167 52.0098 8.86533C57.7158 14.579 60.8573 22.1735 60.8548 30.2509C60.8472 46.9166 47.2874 60.4764 30.6267 60.4764C25.5688 60.4739 20.5846 59.2056 16.1697 56.7961L0.144531 61ZM16.9119 51.3239C21.1717 53.8528 25.2384 55.3677 30.6166 55.3702C44.4636 55.3702 55.7435 44.1005 55.7511 30.2458C55.7562 16.3633 44.5297 5.10875 30.6369 5.10367C16.7797 5.10367 5.50745 16.3734 5.50236 30.2255C5.49982 35.8807 7.15699 40.1151 9.94011 44.5452L7.40099 53.8172L16.9119 51.3239ZM45.8539 37.4362C45.6658 37.121 45.1625 36.933 44.4051 36.5543C43.6502 36.1755 39.9369 34.3481 39.243 34.0965C38.5517 33.8448 38.0484 33.7178 37.5426 34.4752C37.0394 35.23 35.5906 36.933 35.1509 37.4362C34.7112 37.9395 34.2689 38.003 33.5141 37.6243C32.7592 37.2456 30.3243 36.45 27.4395 33.8753C25.1952 31.8725 23.6778 29.3995 23.2381 28.642C22.7984 27.8872 23.1924 27.478 23.5685 27.1018C23.9091 26.7638 24.3234 26.2198 24.7021 25.7776C25.0859 25.3404 25.2104 25.0253 25.4646 24.5195C25.7162 24.0162 25.5917 23.574 25.4011 23.1953C25.2104 22.8191 23.7007 19.1006 23.0729 17.5883C22.4578 16.1167 21.8351 16.315 21.3725 16.2921L19.9238 16.2667C19.4205 16.2667 18.6021 16.4548 17.9108 17.2122C17.2194 17.9696 15.2674 19.7945 15.2674 23.513C15.2674 27.2314 17.9743 30.8228 18.3505 31.326C18.7292 31.8293 23.6753 39.4594 31.252 42.7305C33.054 43.5083 34.4621 43.9734 35.5576 44.3216C37.3672 44.896 39.0142 44.8147 40.3156 44.6215C41.7669 44.4055 44.7838 42.794 45.4142 41.0301C46.0445 39.2637 46.0445 37.7514 45.8539 37.4362Z"
                  fill="#BFA318"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/allineapilates.py/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="67"
                height="67"
                viewBox="0 0 67 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M33.5 6.03838C42.4445 6.03838 43.5053 6.07188 47.0396 6.23379C56.1181 6.64696 60.3586 10.9545 60.7718 19.966C60.9337 23.4975 60.9644 24.5583 60.9644 33.5028C60.9644 42.4501 60.9309 43.5081 60.7718 47.0396C60.3558 56.0427 56.1265 60.3586 47.0396 60.7718C43.5053 60.9337 42.4501 60.9672 33.5 60.9672C24.5555 60.9672 23.4947 60.9337 19.9632 60.7718C10.8624 60.3558 6.64417 56.0288 6.231 47.0368C6.06908 43.5053 6.03558 42.4473 6.03558 33.5C6.03558 24.5555 6.07188 23.4975 6.231 19.9632C6.64696 10.9545 10.8763 6.64417 19.9632 6.231C23.4975 6.07188 24.5555 6.03838 33.5 6.03838ZM33.5 0C24.402 0 23.263 0.0390833 19.6896 0.201C7.52354 0.759333 0.762125 7.50958 0.203792 19.6868C0.0390833 23.263 0 24.402 0 33.5C0 42.598 0.0390833 43.7398 0.201 47.3132C0.759333 59.4793 7.50958 66.2407 19.6868 66.799C23.263 66.9609 24.402 67 33.5 67C42.598 67 43.7398 66.9609 47.3132 66.799C59.4681 66.2407 66.2463 59.4904 66.7962 47.3132C66.9609 43.7398 67 42.598 67 33.5C67 24.402 66.9609 23.263 66.799 19.6896C66.2518 7.53471 59.4932 0.762125 47.316 0.203792C43.7398 0.0390833 42.598 0 33.5 0V0ZM33.5 16.2978C24 16.2978 16.2978 24 16.2978 33.5C16.2978 43 24 50.705 33.5 50.705C43 50.705 50.7023 43.0028 50.7023 33.5C50.7023 24 43 16.2978 33.5 16.2978ZM33.5 44.6667C27.3332 44.6667 22.3333 39.6696 22.3333 33.5C22.3333 27.3332 27.3332 22.3333 33.5 22.3333C39.6668 22.3333 44.6667 27.3332 44.6667 33.5C44.6667 39.6696 39.6668 44.6667 33.5 44.6667ZM51.3834 11.5994C49.1613 11.5994 47.3606 13.4 47.3606 15.6194C47.3606 17.8388 49.1613 19.6394 51.3834 19.6394C53.6028 19.6394 55.4006 17.8388 55.4006 15.6194C55.4006 13.4 53.6028 11.5994 51.3834 11.5994Z"
                    fill="#BFA318"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="67" height="67" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/AllineaPilates.py/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 20H15V30H22.5V60H35V30H44.105L45 20H35V15.8325C35 13.445 35.48 12.5 37.7875 12.5H45V0H35.48C26.49 0 22.5 3.9575 22.5 11.5375V20Z"
                  fill="#BFA318"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className={styles.textContainer2}>
          <p>
            Allinea Pilates © {new Date().getFullYear()}
            {` `}- Todos los derechos reservados
          </p>
        </div>
        <div className={styles.logoContainer}>
          <a
            href="https://lucasboh.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img fluid={data.logoLucas.childImageSharp.fluid} />
          </a>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ContactoQuery {
    portadaM: file(relativePath: { eq: "contacto-p-m.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portada: file(relativePath: { eq: "contacto-p.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoLucas: file(relativePath: { eq: "lucas-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
